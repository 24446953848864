import { AxiosInstance } from 'axios'
import { api } from '../client'
import { serviceUrl } from '../utils'

const controller = serviceUrl('currency')

function createCurrencyService(client: AxiosInstance) {
  return {
    get(params?: CurrencyModel) {
      return client.get<CurrencyViewModel[]>(controller.action('get'), {
        params,
      })
    },
  }
}

export const currencyService = createCurrencyService(api.base)

export interface CurrencyModel {
  currencyKey?: number
}

export interface CurrencyViewModel {
  currencyId: string
  currencyKey: number
  name: string
  code: string
}
