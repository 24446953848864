






































import { PropertyTextTableType } from '@/services/modules/property'
import i18n from '@/setup/i18n'
import { languages } from '@/utils/language'
import { ValidationProvider } from 'vee-validate'
import {
  computed,
  defineComponent,
  PropType,
  reactive,
  watch,
} from '@vue/composition-api'

export default defineComponent({
  name: 'PropertyCreateUpdateSectionText',

  components: {
    ValidationProvider,
  },

  props: {
    item: {
      type: Object as PropType<PropertyTextTableType>,
      required: true,
    },
  },

  emits: {
    update: null,
  },

  setup(props, context) {
    const model = reactive<PropertyTextTableType>({ ...props.item })

    watch(
      () => model,
      (newValue) => {
        context.emit('update', newValue)
      },
      {
        deep: true,
      }
    )

    const locale = computed(
      () =>
        Object.values(languages).find(
          (language) => language.guid === props.item.languageId
        )?.locale
    )

    const titleLabel = computed(
      () =>
        i18n.t('propertyTextTableType.title', {
          locale: locale.value,
        }) as string
    )
    const titleId = computed(() => `title-${props.item.languageId}`)

    const descriptionLabel = computed(
      () =>
        i18n.t('propertyTextTableType.description', {
          locale: locale.value,
        }) as string
    )
    const descriptionId = computed(() => `description-${props.item.languageId}`)
    const descriptionDescription = computed(
      () => i18n.t('propertyTextTableType.descriptionDescription') as string
    )

    return {
      model,
      titleLabel,
      titleId,
      descriptionLabel,
      descriptionId,
      descriptionDescription,
    }
  },
})
