import { CategoryModel, categoryService } from '@/services/modules/category'
import { currentLanguage } from '@/utils/language'
import { computed } from '@vue/composition-api'
import { useService } from './service'

export function useCategories(model?: CategoryModel) {
  const request = computed<CategoryModel>(() => ({
    languageId: currentLanguage.value.guid,
    ...model,
  }))

  return useService(request, () => categoryService.get(request.value))
}
