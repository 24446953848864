import { AxiosInstance } from 'axios'
import { api } from '../client'
import { serviceUrl } from '../utils'
import { ToneViewModel } from './property'

const controller = serviceUrl('category')

function createAgentService(client: AxiosInstance) {
  return {
    get(params: CategoryModel) {
      return client.get<CategoryViewModel[]>(controller.action('get'), {
        params,
      })
    },
  }
}

export const categoryService = createAgentService(api.base)

export interface CategoryModel {
  languageId?: string
}

export interface CategoryViewModel {
  categoryId: string
  categoryKey: number
  name: string
  title: string
  description: string
  displayOrder: number
  toneViewModel: ToneViewModel
}
