import { GroupModel, groupService } from '@/services/modules/group'
import { currentLanguage } from '@/utils/language'
import { computed, ComputedRef, isRef } from '@vue/composition-api'
import { useService } from './service'

export function useGroups(model?: ComputedRef<GroupModel> | GroupModel) {
  const request = computed<GroupModel>(() => ({
    languageId: currentLanguage.value.guid,
    ...(isRef(model) ? model.value : model),
  }))
  return useService(request, () => groupService.get(request.value))
}
