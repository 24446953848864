





















import { DetailValueTableType } from '@/services/modules/property'
import i18n from '@/setup/i18n'
import { languages } from '@/utils/language'
import { ValidationProvider } from 'vee-validate'
import {
  computed,
  defineComponent,
  PropType,
  reactive,
  watch,
} from '@vue/composition-api'

export default defineComponent({
  name: 'PropertyCreateUpdateSectionDetail',

  components: {
    ValidationProvider,
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    item: {
      type: Object as PropType<DetailValueTableType>,
      required: true,
    },
  },

  emits: {
    update: null,
  },

  setup(props, context) {
    const model = reactive<DetailValueTableType>({ ...props.item })

    watch(
      () => model,
      (newValue) => {
        context.emit('update', newValue)
      },
      {
        deep: true,
      }
    )

    const locale = computed(
      () =>
        Object.values(languages).find(
          (language) => language.guid === props.item.languageId
        )?.locale
    )

    const valueLabel = computed(
      () =>
        i18n.t('detailValueTableType.value', {
          title: props.title,
          locale: locale.value,
        }) as string
    )
    const valueId = computed(() => `value-${props.item.languageId}`)

    return {
      model,
      valueLabel,
      valueId,
    }
  },
})
