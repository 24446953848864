import { AxiosInstance } from 'axios'
import { api } from '../client'
import { serviceUrl } from '../utils'
import { ToneViewModel } from './property'

const controller = serviceUrl('tag')

function createTagService(client: AxiosInstance) {
  return {
    get(params: TagModel) {
      return client.get<TagViewModel[]>(controller.action('get'), {
        params,
      })
    },
  }
}

export const tagService = createTagService(api.base)

export interface TagModel {
  languageId?: string
  searchBy?: string
  isDeleted?: boolean
}

export interface TagViewModel {
  tagId: string
  tagKey: number
  name: string
  title: string
  description: string
  toneViewModel: ToneViewModel
}
