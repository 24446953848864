





































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import PropertyCreateUpdateSectionDetail from '@/components/PropertyCreateUpdateSectionDetail.vue'
import PropertyCreateUpdateSectionText from '@/components/PropertyCreateUpdateSectionText.vue'
import {
  DetailValueTableType,
  PropertyCategoryId,
  PropertyDetailViewModel,
  PropertyInsertModel,
  PropertyUpdateModel,
  propertyService,
  PropertyTextTableType,
  FileTableType,
} from '@/services/modules/property'
import { CategoryViewModel } from '@/services/modules/category'
import { TagViewModel } from '@/services/modules/tag'
import { TypeViewModel } from '@/services/modules/type'
import { AgentViewModel } from '@/services/modules/agent'
import { AreaGetViewModel } from '@/services/modules/area'
import { useCurrency } from '@/composition/currency'
import { useCategories } from '@/composition/categories'
import { useTags } from '@/composition/tags'
import { useTypes } from '@/composition/types'
import { useAgents } from '@/composition/agents'
import { useAreas } from '@/composition/areas'
import { useGroups } from '@/composition/groups'
import { languages } from '@/utils/language'
import {
  getDisplaySize,
  getExtension,
  getNameWithoutExtension,
  toBase64,
} from '@/utils/file'
import communique from '@/notification'
import {
  VCalendarDateRange,
  VCalendarDateRangeInputEvent,
} from '@/setup/v-calendar'
import i18n from '@/setup/i18n'
import router from '@/router'
import { isSameDay, parseISO, subHours } from 'date-fns'
import Draggable from 'vuedraggable'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  computed,
  defineComponent,
  onBeforeMount,
  reactive,
  ref,
  watch,
} from '@vue/composition-api'
import { CurrencyViewModel, currencyService } from '@/services/modules/currency'

interface SelectDetail {
  detailId: string
  title: string
  detailValueTableType: DetailValueTableType[]
}

interface SelectedGroup {
  groupId: string
  title: string
  description: string
  details: SelectDetail[]
}

interface RejectedFile {
  file: File
  errorKey: 'fileSizeError' | 'fileTypeError'
}

export default defineComponent({
  name: 'PropertyCreateUpdateForm',

  components: {
    PropertyCreateUpdateSectionDetail,
    PropertyCreateUpdateSectionText,
    Draggable,
    ValidationObserver,
    ValidationProvider,
  },

  props: {
    propertyKey: {
      type: Number,
      default: 0,
    },
  },

  setup(props) {
    const appLanguages = Object.values(languages)

    const mode = ref(props.propertyKey ? 'update' : 'create')

    const isLoadingProperty = ref(false)

    const fetchPropertyError = ref<unknown | null>(null)

    const form = ref<HTMLFormElement | null>(null)

    const isSubmitting = ref(false)

    const isDragAreaActive = ref(false)

    const isLandType = ref(false)

    const model: PropertyInsertModel | PropertyUpdateModel =
      mode.value === 'update'
        ? reactive<PropertyUpdateModel>({
            propertyId: '',
            areaId: '',
            agentId: '',
            typeId: '',
            categoryId: '',
            tagId: '',
            currencyId: '',
            addressId: '',
            addressName: '',
            addressLineOne: '',
            addressLineTwo: '',
            addressPostalCode: '',
            addressLatitude: '',
            addressLongitude: '',
            mlsPropertyId: '',
            price: 0,
            price2: 0,
            cleaningFee: 0,
            bathroom: 0,
            bedroom: 0,
            isRecommendation: false,
            isFeatureListing: false,
            virtualTour: '',
            displayOrder: 0,
            detailValueTableType: [],
            propertyTextTableType: [],
            calendarTableType: [],
            fileTableType: [],
            fileListToDelete: [],
          })
        : reactive<PropertyInsertModel>({
            areaId: '',
            agentId: '',
            typeId: '',
            categoryId: '',
            tagId: '',
            currencyId: '',
            addressName: '',
            addressLineOne: '',
            addressLineTwo: '',
            addressPostalCode: '',
            addressLatitude: '',
            addressLongitude: '',
            mlsPropertyId: '',
            price: 0,
            price2: 0,
            cleaningFee: 0,
            bathroom: 0,
            bedroom: 0,
            isRecommendation: false,
            isFeatureListing: false,
            isDeleted: false,
            virtualTour: '',
            displayOrder: 0,
            detailValueTableType: [],
            propertyTextTableType: appLanguages.map<PropertyTextTableType>(
              (language) => ({
                propertyId: '00000000-0000-0000-0000-000000000000',
                languageId: language.guid,
                title: '',
                description: '',
              })
            ),
            calendarTableType: [],
            fileTableType: [],
          })

    watch(
      () => model.categoryId,
      (newVal) => {
        if (newVal === PropertyCategoryId.ForSale) {
          if (model.price2) {
            model.price2 = 0
          }
          if (model.cleaningFee) {
            model.cleaningFee = 0
          }
          if (model.calendarTableType.length > 0) {
            clearAllDates()
          }
        }
      }
    )

    onBeforeMount(() => {
      if (mode.value === 'update') {
        fetchProperty()
      }
    })

    const isLoadingCurrencies = ref(false)
    const currencies = ref<CurrencyViewModel[]>([])

    async function fetchCurrencies() {
      try {
        isLoadingCurrencies.value = true
        const { data } = await currencyService.get()
        currencies.value = data
      } catch (error) {
        currencies.value = []
      } finally {
        isLoadingCurrencies.value = false
      }
    }

    fetchCurrencies().then(() => {
      if (props.propertyKey) return
      const defaultCurrency = currencies.value.find(
        (currency) => currency.code === 'USD'
      )

      if (!defaultCurrency) return

      model.currencyId = defaultCurrency.currencyId
    })

    const currency = computed<CurrencyViewModel | undefined>({
      get: () => {
        return currencies.value.find(
          (currency) => currency.currencyId === model.currencyId
        )
      },
      set: (newValue) => {
        model.currencyId = newValue?.currencyId
      },
    })

    const rentedDateRanges = computed(() =>
      model.calendarTableType.map<VCalendarDateRange>((item) => ({
        start: item.startingDate,
        end: item.endingDate,
      }))
    )

    function onRentedDateRangesUpdate(range: VCalendarDateRangeInputEvent) {
      const idx = model.calendarTableType.findIndex(
        (item) =>
          isSameDay(item.startingDate, range.start) &&
          isSameDay(item.endingDate, range.end)
      )
      if (idx !== -1) {
        model.calendarTableType.splice(idx, 1)
      } else {
        model.calendarTableType.push({
          startingDate: subHours(range.start, 7),
          endingDate: subHours(range.end, 7),
        })
      }
    }

    function clearAllDates() {
      model.calendarTableType = []
    }

    const files = ref<File[]>([])
    const rejectedFiles = ref<RejectedFile[]>([])

    watch(
      () => files.value,
      (newValue) => {
        addImages(newValue)
      }
    )

    const isRental = computed(
      () => model.categoryId === PropertyCategoryId.Rentals
    )

    const areaIdLabel = computed(() => i18n.t('areaId') as string)
    const countryNameLabel = computed(() => i18n.t('country') as string)
    const stateNameLabel = computed(() => i18n.t('state') as string)
    const cityNameLabel = computed(() => i18n.t('city') as string)
    const agentIdLabel = computed(() => i18n.t('agentId') as string)
    const typeIdLabel = computed(() => i18n.t('typeId') as string)
    const categoryIdLabel = computed(() => i18n.t('categoryId') as string)
    const tagIdLabel = computed(() => i18n.t('tagId') as string)
    const currencyIdLabel = computed(() => i18n.t('currencyId') as string)
    const addressNameLabel = computed(() => i18n.t('addressName') as string)
    const addressLineOneLabel = computed(
      () => i18n.t('addressLineOne') as string
    )
    const addressLineTwoLabel = computed(
      () => i18n.t('addressLineTwo') as string
    )
    const addressPostalCodeLabel = computed(
      () => i18n.t('addressPostalCode') as string
    )
    const addressLatitudeLabel = computed(
      () => i18n.t('addressLatitude') as string
    )
    const addressLongitudeLabel = computed(
      () => i18n.t('addressLongitude') as string
    )
    const mlsPropertyIdLabel = computed(() => i18n.t('mlsPropertyId') as string)
    const mlsPropertyIdDescription = computed(
      () => i18n.t('mlsPropertyIdDescription') as string
    )
    const priceLabel = computed(() => i18n.t('price') as string)
    const priceAsCurrency = computed(() => {
      if (!model.price) return
      const formattedPrice = useCurrency({
        value: model.price || 0,
        currency: currency.value,
      }).value
      return isRental.value
        ? `${formattedPrice} ${i18n.t('perNight')}`
        : formattedPrice
    })
    const price2Label = computed(() => i18n.t('price2') as string)
    const price2AsCurrency = computed(() => {
      if (!model.price2) return
      const formattedPrice2 = useCurrency({
        value: model.price2 || 0,
        currency: currency.value,
      }).value
      return isRental.value
        ? `${formattedPrice2} ${i18n.t('perMonth')}`
        : formattedPrice2
    })
    const cleaningFeeLabel = computed(() => i18n.t('cleaningFee') as string)
    const cleaningFeeAsCurrency = computed(() => {
      if (!model.cleaningFee) return
      const formattedCleaningFee = useCurrency({
        value: model.cleaningFee || 0,
        currency: currency.value,
      }).value
      return formattedCleaningFee
    })
    const bathroomLabel = computed(() => i18n.t('bathroom') as string)
    const bedroomLabel = computed(() => i18n.t('bedroom') as string)
    const isRecommendationLabel = computed(
      () => i18n.t('isRecommendation') as string
    )
    const isFeatureListingLabel = computed(
      () => i18n.t('isFeatureListing') as string
    )
    const isDeletedLabel = computed(() => i18n.t('active') as string)
    const virtualTourLabel = computed(() => i18n.t('virtualTour') as string)
    const virtualTourDescription = computed(
      () => i18n.t('virtualTourDescription') as string
    )
    const detailValueTableTypeLabel = computed(
      () => i18n.t('detailValueTableType.title') as string
    )
    const optionalLabel = computed(() =>
      i18n.t('optional').toString().toLowerCase()
    )
    const readonlyLabel = computed(() =>
      i18n.t('readonly').toString().toLowerCase()
    )

    const fileTableTypeCount = computed(() => model.fileTableType.length)
    const rejectedFilesCount = computed(() => rejectedFiles.value.length)

    const { data: categories, isLoading: isLoadingCategories } = useCategories()

    const category = computed<CategoryViewModel | undefined>({
      get: () => {
        return categories.value.find((x) => x.categoryId === model.categoryId)
      },
      set: (newValue) => {
        model.categoryId = newValue?.categoryId
      },
    })

    const { data: tags, isLoading: isLoadingTags } = useTags()

    const tag = computed<TagViewModel | undefined>({
      get: () => {
        return tags.value.find((x) => x.tagId === model.tagId)
      },
      set: (newValue) => {
        model.tagId = newValue?.tagId
      },
    })

    const { data: types, isLoading: isLoadingTypes } = useTypes()

    const type = computed<TypeViewModel | undefined>({
      get: () => {
        return types.value.find((x) => x.typeId === model.typeId)
      },
      set: (newValue) => {
        model.typeId = newValue?.typeId
      },
    })

    watch(
      () => type.value,
      (newValue) => {
        if (!newValue) return
        let flag = false
        if (newValue.name === 'Land') {
          flag = true
          model.bathroom = 0
          model.bedroom = 0
        }
        isLandType.value = flag
      }
    )

    const { data: agents, isLoading: isLoadingAgents } = useAgents()

    const agent = computed<AgentViewModel | undefined>({
      get: () => {
        return agents.value.find((x) => x.agentId === model.agentId)
      },
      set: (newValue) => {
        model.agentId = newValue?.agentId
      },
    })

    const { data: areas, isLoading: isLoadingAreas } = useAreas()

    const area = computed<AreaGetViewModel | undefined>({
      get: () => {
        return areas.value.find((x) => x.areaId === model.areaId)
      },
      set: (newValue) => {
        model.areaId = newValue?.areaId
      },
    })

    const { data: groups, isLoading: isLoadingGroups } = useGroups()

    const details = computed<PropertyDetailViewModel[] | undefined>({
      get: () => {
        const modelList: DetailValueTableType[] = [
          ...model.detailValueTableType,
        ]
        const list: PropertyDetailViewModel[] = []
        for (const group of groups.value) {
          for (const detail of group.detailList) {
            const idx = modelList.findIndex(
              (x) => x.detailId === detail.detailId
            )
            if (idx !== -1) {
              list.push(detail)
              modelList.splice(idx, 1)
            }
          }
        }
        return list
      },
      set: (newValue) => {
        let result: DetailValueTableType[] = []
        if (newValue) {
          for (const detail of newValue) {
            const currentDetails = model.detailValueTableType.filter(
              (x) => x.detailId === detail.detailId
            )
            result = [
              ...result,
              ...(currentDetails.length === 0
                ? appLanguages.map<DetailValueTableType>((x) => ({
                    detailId: detail.detailId,
                    languageId: x.guid,
                    value: '',
                  }))
                : currentDetails),
            ]
          }
        }
        model.detailValueTableType = result
      },
    })

    const selectedGroups = computed(() => {
      let list: SelectedGroup[] = []

      if (details.value) {
        for (const detail of details.value) {
          const selectedDetail: SelectDetail = {
            detailId: detail.detailId,
            title: detail.propertyDetailTitle,
            detailValueTableType: model.detailValueTableType.filter(
              (d) => d.detailId === detail.detailId
            ),
          }
          const group = list.find((x) => x.groupId === detail.groupId)
          if (group) {
            group.details.push(selectedDetail)
          } else {
            const currentGroup = groups.value.find(
              (g) => g.groupId === detail.groupId
            )
            list.push({
              groupId: detail.groupId,
              title: currentGroup?.title || '',
              description: currentGroup?.description || '',
              details: [selectedDetail],
            })
          }
        }
      }

      return list
    })

    function onPropertyTextTableTypeUpdate(newValue: PropertyTextTableType) {
      const idx = model.propertyTextTableType.findIndex(
        (item) => item.languageId === newValue.languageId
      )
      if (idx === -1) return
      model.propertyTextTableType.splice(idx, 1, newValue)
    }

    function onDetailTableTypeUpdate(newValue: DetailValueTableType) {
      const idx = model.detailValueTableType.findIndex(
        (item) =>
          item.detailId === newValue.detailId &&
          item.languageId === newValue.languageId
      )
      if (idx === -1) return
      model.detailValueTableType.splice(idx, 1, newValue)
    }

    async function submit() {
      if (fileTableTypeCount.value === 0) {
        communique.dispatch({
          variant: 'error',
          message: i18n.t('imagesRequiredError') as string,
        })
        return
      }

      const isUpdate = mode.value === 'update'

      try {
        isSubmitting.value = true

        if (isInUpdateMode(model)) {
          await propertyService.update(model)
        } else {
          await propertyService.insert(model)
        }

        router.push({
          name: 'PropertyList',
          query: {
            searchBy: model.propertyTextTableType[0].title,
          },
        })

        communique.dispatch({
          variant: 'success',
          message: i18n.t(
            isUpdate ? 'propertyUpdateSuccess' : 'propertyInsertSuccess',
            {
              title: model.propertyTextTableType[0].title,
            }
          ) as string,
        })
      } catch (error) {
        communique.dispatch({
          variant: 'error',
          message: i18n.t(
            isUpdate ? 'propertyUpdateError' : 'propertyInsertError',
            {
              title: model.propertyTextTableType[0].title,
            }
          ) as string,
        })
      } finally {
        isSubmitting.value = false
      }
    }

    // 1 MB
    const maxImageAllowedSize = 1 * 1024 * 1024
    const imageAllowedTypes = ['image/png', 'image/jpeg']
    const fileAccept = imageAllowedTypes.join(',')

    function addImages(files: File[]) {
      const acceptedFiles: File[] = []

      for (const file of files) {
        if (!imageAllowedTypes.includes(file.type)) {
          rejectedFiles.value.push({
            file,
            errorKey: 'fileTypeError',
          })
        } else if (file.size > maxImageAllowedSize) {
          rejectedFiles.value.push({
            file,
            errorKey: 'fileSizeError',
          })
        } else {
          acceptedFiles.push(file)
        }
      }

      for (let index = 0; index < acceptedFiles.length; index++) {
        const file = acceptedFiles[index]
        toBase64(file).then((dataUrl) =>
          model.fileTableType.push({
            dataUrl: dataUrl.split(',')[1],
            name: getNameWithoutExtension(file.name),
            description: '',
            extension: getExtension(file.name) || '',
            path: '',
            displayOrder: model.fileTableType.length + 1,
          })
        )
      }
    }

    function isInUpdateMode(
      model: PropertyInsertModel | PropertyUpdateModel
    ): model is PropertyUpdateModel {
      return mode.value === 'update' && 'propertyId' in model
    }

    function dropImages(ev: DragEvent) {
      isDragAreaActive.value = false
      if (!ev.dataTransfer || ev.dataTransfer.files.length === 0) return
      addImages(Array.from(ev.dataTransfer.files))
    }

    function removeAllImages() {
      for (const image of model.fileTableType) {
        handleDeletedImages(image)
      }
      model.fileTableType = []
      clearRejectedFiles()
    }

    function clearRejectedFiles() {
      rejectedFiles.value = []
    }

    function removeRejectedFile(index: number) {
      rejectedFiles.value.splice(index, 1)
    }

    function removeImage(imageIndex: number) {
      handleDeletedImages(model.fileTableType[imageIndex])
      const nextFiles = model.fileTableType.filter(
        (f, index) => index > imageIndex
      )
      for (let index = 0; index < nextFiles.length; index++) {
        const file = nextFiles[index]
        file.displayOrder = imageIndex + index + 1
      }
      model.fileTableType.splice(imageIndex, 1)
    }

    function handleDeletedImages(image: FileTableType) {
      if (isInUpdateMode(model) && image.path) {
        model.fileListToDelete.push(image)
      }
    }

    function updateImagesDisplayOrder() {
      for (let index = 0; index < model.fileTableType.length; index++) {
        const file = model.fileTableType[index]
        file.displayOrder = index + 1
      }
    }

    async function fetchProperty() {
      try {
        if (!isInUpdateMode(model)) {
          throw new Error('The component is not in update mode.')
        }

        fetchPropertyError.value = null
        isLoadingProperty.value = true

        const responses = await Promise.all(
          appLanguages.map((language) =>
            propertyService.get({
              pageNumber: 1,
              rowsPerPage: 1,
              languageId: language.guid,
              propertyKey: props.propertyKey,
            })
          )
        )

        for (let index = 0; index < responses.length; index++) {
          const {
            data: { items },
          } = responses[index]
          const language = appLanguages[index]
          if (items.length === 0) {
            throw new Error(`Property not found for ${language.locale} locale.`)
          }

          const [property] = items

          if (index === 0) {
            model.propertyId = property.propertyId
            model.areaId = property.areaViewModel.areaId
            model.agentId = property.agentViewModel.agentId
            model.typeId = property.typeViewModel.typeId
            model.categoryId = property.categoryViewModel.categoryId
            model.tagId = property.tagViewModel?.tagId || ''
            model.currencyId = property.currencyViewModel.currencyId
            model.addressId = property.addressViewModel.addressId
            model.addressName = property.addressViewModel.name
            model.addressLineOne = property.addressViewModel.lineOne
            model.addressLineTwo = property.addressViewModel.lineTwo
            model.addressPostalCode = property.addressViewModel.postalCode
            model.addressLatitude = property.addressViewModel.latitude
            model.addressLongitude = property.addressViewModel.longitude
            model.mlsPropertyId = property.mlsPropertyId
            model.price = property.price
            model.price2 = property.price2
            model.cleaningFee = property.cleaningFee
            model.bathroom = property.bathroom
            model.bedroom = property.bedroom
            model.isRecommendation = property.isRecommendation
            model.isFeatureListing = property.isFeatureListing
            model.isDeleted = property.isDeleted
            model.virtualTour = property.virtualTour
            model.displayOrder = property.displayOrder
            model.calendarTableType = property.calendarList.map((item) => ({
              startingDate: subHours(parseISO(item.startingDate), 7),
              endingDate: subHours(parseISO(item.endingDate), 7),
            }))
            model.fileTableType = property.propertyImageList.map((image) => ({
              dataUrl: '',
              name: image.name,
              description: image.description || '',
              extension: getExtension(image.name) || '',
              path: image.path,
              displayOrder: image.displayOrder,
            }))
            model.fileListToDelete = []
          }

          for (const group of property.propertyGroupList) {
            for (const detail of group.detailList) {
              model.detailValueTableType.push({
                detailId: detail.detailId,
                languageId: language.guid,
                value: detail.value,
              })
            }
          }
          model.propertyTextTableType.push({
            propertyId: property.propertyId,
            languageId: language.guid,
            title: property.title,
            description: property.description,
          })
        }
      } catch (error) {
        fetchPropertyError.value = error
      } finally {
        isLoadingProperty.value = false
      }
    }

    return {
      mode,
      form,
      isDragAreaActive,
      model,
      rentedDateRanges,
      onRentedDateRangesUpdate,
      clearAllDates,
      isLoadingProperty,
      isSubmitting,
      submit,
      dropImages,
      fetchProperty,
      fetchPropertyError,
      fileAccept,
      removeImage,
      removeAllImages,
      clearRejectedFiles,
      removeRejectedFile,
      files,
      rejectedFiles,
      updateImagesDisplayOrder,
      areaIdLabel,
      countryNameLabel,
      stateNameLabel,
      cityNameLabel,
      agentIdLabel,
      typeIdLabel,
      categoryIdLabel,
      tagIdLabel,
      currencyIdLabel,
      addressNameLabel,
      addressLineOneLabel,
      addressLineTwoLabel,
      addressPostalCodeLabel,
      addressLatitudeLabel,
      addressLongitudeLabel,
      mlsPropertyIdLabel,
      mlsPropertyIdDescription,
      priceLabel,
      priceAsCurrency,
      price2Label,
      price2AsCurrency,
      cleaningFeeLabel,
      cleaningFeeAsCurrency,
      bathroomLabel,
      bedroomLabel,
      isRecommendationLabel,
      isFeatureListingLabel,
      isDeletedLabel,
      virtualTourLabel,
      virtualTourDescription,
      detailValueTableTypeLabel,
      optionalLabel,
      readonlyLabel,
      isRental,
      fileTableTypeCount,
      rejectedFilesCount,
      onPropertyTextTableTypeUpdate,
      onDetailTableTypeUpdate,
      categories,
      isLoadingCategories,
      category,
      tags,
      isLoadingTags,
      tag,
      types,
      isLoadingTypes,
      type,
      agents,
      isLoadingAgents,
      agent,
      areas,
      isLoadingAreas,
      area,
      currencies,
      isLoadingCurrencies,
      currency,
      groups,
      isLoadingGroups,
      details,
      selectedGroups,
      getDisplaySize,
      isLandType,
    }
  },
})
