import { AxiosInstance } from 'axios'
import { api } from '../client'
import { serviceUrl } from '../utils'
import { PropertyGroupViewModel } from './property'

const controller = serviceUrl('group')

function createGroupService(client: AxiosInstance) {
  return {
    get(params: GroupModel) {
      return client.get<PropertyGroupViewModel[]>(controller.action('get'), {
        params,
      })
    },
  }
}

export const groupService = createGroupService(api.base)

export interface GroupModel {
  languageId?: string
  isCompareListing?: boolean
}
